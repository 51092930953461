<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <div
      v-if="result"
    >
      <h4 class="card-title">
        {{ result.head }}
      </h4>
      <div v-html="result.html" />
    </div>
  </div>
</template>
<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import callbackPageStoreModule from './callbackPageStoreModule'
import store from '@/store'

export default {
  components: {
    BOverlay,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'callback-page'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, callbackPageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { router } = useRouter()

    const apiId = router.currentRoute.params.id
    const params = {
      size: `${window.innerWidth}x${window.innerHeight}`,
    }

    const result = ref(null)
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      store.dispatch('callback-page/fetchData', {
        api: props.api,
        apiId,
        params,
      })
        .then(response => {
          const { data } = response
          result.value = data
          loading.value = false

          if (data.url) {
            if (data.timeOut) {
              setTimeout(() => {
                window.location = data.url
              }, data.timeOut < 1000 ? data.timeOut * 1000 : data.timeOut)
            } else {
              window.location = data.url
            }
          }
        })
        .catch(() => {
          result.value = null
          loading.value = false
        })
    }

    if (props.api) {
      fetchData()
    }

    return {
      loading,
      result,
    }
  },
}
</script>
