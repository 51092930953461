        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
                            <callback-page
  api="https://api.vestaorganic.ru/api/v1/htm/lk/proc/payment/sbpayok"
/>            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import CallbackPage from '@core/components/callback-page/CallbackPage.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          CallbackPage,
                                          },
  setup() {
    
      },
}
</script>
    